<template>
  <v-dialog v-model="internalOpen" fullscreen>
    <div
      style="
        background-color: #ffffff;
        padding: 0 1em 1em;
        position: relative;
        height: inherit;
      "
    >
      <v-container style="position: relative">
        <div
          class="py-4"
          style="
            width: 100%;
            background-color: inherit;
            position: sticky;
            top: 0;
            z-index: 10;
          "
        >
          <v-btn
            style="
              min-width: unset !important;
              min-height: unset !important;
              width: 32px !important;
              height: 32px !important;
            "
            class="px-4 py-4"
            @click.stop="cerrarModal"
            text
            rounded
          >
            <v-icon>mdi-window-close</v-icon>
          </v-btn>
        </div>
        <div class="px-1 mt-1">
          <v-form ref="form">
            <v-container fluid>
              <v-row>
                <v-col cols="12" md="6">
                  <v-select
                    v-model="form.id_anio_fiscal"
                    label="Año fiscal"
                    outlined
                    :items="aniosFiscales"
                    :loading="aniosFiscalesCargando"
                    placeholder="Seleccione un año"
                    item-value="id"
                    item-text="anio"
                    clearable
                    :error-messages="id_anio_fiscalErrors"
                    @change="$v.form.id_anio_fiscal.$touch()"
                    @blur="$v.form.id_anio_fiscal.$touch()"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-text-field
                    label="Numero de proceso"
                    placeholder="Numero de proceso"
                    type="number"
                    outlined
                    v-model="form.numero_proceso"
                    :error-messages="numeroProcesoErrors"
                    @change="$v.form.numero_proceso.$touch()"
                    @blur="$v.form.numero_proceso.$touch()"
                  />
                </v-col>

                <v-col cols="12" md="6">
                  <v-menu transition="scale-transition" min-width="auto">
                    <template v-slot:activator="{ on, attrs }">
                      <span v-bind="attrs" v-on="on">
                        <v-text-field
                          prepend-inner-icon="mdi-calendar"
                          clearable
                          label="Fecha de aprobación"
                          outlined
                          placeholder="Seleccione una fecha"
                          readonly
                          :value="obtenerFechaAprobacion"
                          :error-messages="fechaAprobacionErrors"
                          @change="$v.form.fecha_aprobacion.$touch()"
                          @blur="$v.form.fecha_aprobacion.$touch()"
                        />
                      </span>
                    </template>
                    <div @click.stop>
                      <vc-date-picker
                        v-model="form.fecha_aprobacion"
                        mode="date"
                        locale="es"
                        :max-date="fecha_maxima"
                      />
                    </div>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="6">
                  <v-file-input
                    v-model="form.adjunto"
                    label="PDF"
                    placeholder="Seleccione un PDF"
                    outlined
                    light
                    :error-messages="adjuntoErrors"
                    @change="$v.form.adjunto.$touch()"
                    @blur="$v.form.adjunto.$touch()"
                    accept="application/pdf"
                  />
                </v-col>
              </v-row>
              <div>
                <v-btn
                  tile
                  color="primary"
                  v-if="siendoEditado"
                  @click="cancelarActualizar"
                >
                  <v-icon class="white--text" left> mdi-cancel </v-icon>
                  Cancelar editar
                </v-btn>
              </div>
            </v-container>
          </v-form>

          <!-- DataTable -->
          <DataTableComponent
            dense
            noGutters
            :headers="headers"
            :items="documentos.data"
            :show_loading="documentos.isLoading"
            :total_registros="total"
            v-models:pagina="paginaActual"
            v-models:select="registrosPorPagina"
            @paginar="manejarPaginacionRegistros"
          >
            <template v-slot:[`item.acciones`]="{ item }">
              <div class="d-flex justify-center align-center" style="gap: 8px">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop="visualizarAdjunto(item)"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>Ver adjunto</span>
                </v-tooltip>

                <v-tooltip v-if="!item.aprobado" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop="confirmarEliminarAdjunto(item)"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </template>
                  <span>Eliminar adjunto</span>
                </v-tooltip>

                <v-tooltip v-if="!item.aprobado" top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      @click.stop="editarDocumento(item)"
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      icon
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Editar</span>
                </v-tooltip>
              </div>
            </template>
            <template v-slot:[`item.numero_proceso`]="{ item }">
              {{ item.numero_proceso ? item.numero_proceso : "-" }}
            </template>
          </DataTableComponent>

          <!-- Botones de acción -->
          <div class="my-4 d-flex flex-wrap flex-md-nowrap" style="gap: 16px">
            <v-btn
              @click.stop="cerrarModal"
              class="flex-grow-1 flex-shrink-1"
              color="secondary"
            >
              Cerrar
            </v-btn>
            <v-btn
              @click="handleSubmit"
              class="flex-grow-1 flex-shrink-1"
              color="primary"
              :loading="guardandoDocumento"
              :disabled="$v.$invalid"
            >
              {{ opcionesDeGuardado }}
            </v-btn>
          </div>
        </div>

        <!-- confirmar guardado -->
        <ConfirmationModalComponent
          :is-open="confirmarGuardadoAbierta"
          description="Desea guardar un nuevo documento"
          :is-loading="guardandoDocumento"
          @confirm="guardarAdjunto"
          @cancel="confirmarGuardadoAbierta = false"
        />
        <!-- confirmar eliminacion -->
        <ConfirmationModalComponent
          :is-open="confirmarEliminacionAbierta"
          description="Desea eliminar el documento"
          :is-loading="eliminandoDocumento"
          @confirm="eliminarAdjunto"
          @cancel="confirmarEliminacionAbierta = false"
        />

        <!-- confirmar actualizar -->
        <ConfirmationModalComponent
          :is-open="confirmarActualizarAbierta"
          description="Desea actualizar el documento"
          :is-loading="actualizarDocumento"
          @confirm="actualizarDocumentoPAC"
          @cancel="confirmarActualizarAbierta = false"
        />

        <PdfModal
            :isOpen.sync="previsualizacionAbierta" 
            :source-loadable="visualizacionAdjunto"
            filename="documento-autorizacion"
        />
      </v-container>
    </div>
  </v-dialog>
</template>
<script>
import DataTableComponent from "@/components/DataTableComponent.vue";
import { Validator } from "@/utils/form-validation.js";
import { required } from "vuelidate/lib/validators";
import PdfModal from "@/components/PdfModal.vue";
import { convertToFormData } from "@/utils/data";
import { ConfirmationModalComponent } from "@/components/utils";
import {
  createLoadable,
  setLoadableResponse,
  toggleLoadable,
  createPageable,
  setPageableResponse,
  togglePageable,
} from "@/utils/loadable";
import { isNil } from "lodash";
import moment from "moment";
import { minValue } from "vuelidate/lib/validators";

const initialFormState = {
  id_anio_fiscal: null,
  adjunto: null,
  numero_proceso: null,
  fecha_aprobacion: null,
};

export default {
  name: "ModalCargaDocsAnioFiscalPac",
  emits: ["on-visibility-change", "on-save", "on-action"],
  components: { DataTableComponent, PdfModal, ConfirmationModalComponent },

  validations() {
    return {
      form: {
        id_anio_fiscal: { required },
        numero_proceso: { required, minValue: minValue(1) },
        fecha_aprobacion: { required },
        adjunto: this.siendoEditado ? {} : { required },
      },
    };
  },

  props: {
    isOpen: { type: Boolean },
    aniosFiscales: { type: Array },
    aniosFiscalesCargando: { type: Boolean },
  },
  data: () => ({
    internalOpen: false,
    fecha_maxima: moment().format("YYYY/MM/DD"),
    form: { ...initialFormState },
    headers: [
      {
        align: "center",
        sortable: false,
        text: "Año",
        value: "AnioFiscal.anio",
      },
      {
        align: "center",
        sortable: false,
        text: "Numero de procesos",
        value: "numero_proceso",
      },
      { align: "center", sortable: false, text: "Acciones", value: "acciones" },
    ],
    // utilidades
    documentoActivo: null,
    informacionGuardar: null,
    // Paginacion
    paginaActual: 1,
    registrosPorPagina: 10,
    // Guardado de documento
    guardandoDocumento: false,
    confirmarGuardadoAbierta: false,
    // eliminar documento
    eliminandoDocumento: false,
    confirmarEliminacionAbierta: false,
    // Actualizar documento
    actualizarDocumento: false,
    confirmarActualizarAbierta: false,
    // Ver adjunto
    modalVisualizacionAdjuntoAbierta: false,
    visualizacionAdjunto: createLoadable(null),
    documentos: createPageable([], 10),
    siendoEditado: false,
    idDocumentoAnioFiscal: 0,
    previsualizacionAbierta: false,
  }),
  computed: {
    id_anio_fiscalErrors() {
      return new Validator(this.$v.form.id_anio_fiscal).detect().getResult();
    },
    adjuntoErrors() {
      return new Validator(this.$v.form.adjunto).detect().getResult();
    },
    numeroProcesoErrors() {
      return new Validator(this.$v.form.numero_proceso).detect().getResult();
    },
    fechaAprobacionErrors() {
      return new Validator(this.$v.form.fecha_aprobacion).detect().getResult();
    },
    total() {
      return this.documentos.pagination.total_rows;
    },
    obtenerFechaAprobacion() {
      if (this.form.fecha_aprobacion) {
        return moment(this.form.fecha_aprobacion).format("DD/MM/YYYY");
      }
    },
    opcionesDeGuardado() {
      return this.siendoEditado ? "Actualizar" : "Guardar";
    },
  },
  methods: {
    cerrarModal() {
      this.clearForm();
      this.internalOpen = false;
      this.siendoEditado = false;
    },
    clearForm() {
      this.$v.form.$reset();
      this.$refs.form.reset();
      this.$v.form.adjunto.$reset();
      this.form = initialFormState;
    },
    handleSubmit() {
      this.$v.$touch();

      if (this.$v.$invalid) return;
      this.informacionGuardar = { ...this.form };
      this.siendoEditado
        ? (this.confirmarActualizarAbierta = true)
        : (this.confirmarGuardadoAbierta = true);
    },
    // crud adjuntos
    async guardarAdjunto(data) {
      try {
        this.guardandoDocumento = true;
        const formData = convertToFormData(this.informacionGuardar);
        const { status } =
          await this.services.DocumentosAniosFiscalServices.guardarDocumento(
            formData
          );
        if ([200, 201].includes(status)) {
          this.pushAppMessage({
            type: "success",
            message: "Documento guardado correctamente.",
          });
          this.clearForm();
          this.cargarDocumentosAnioFiscalPac();
          this.$emit("on-action");
        }
      } catch (error) {
      } finally {
        this.guardandoDocumento = false;
        this.confirmarGuardadoAbierta = false;
      }
    },
    confirmarEliminarAdjunto(adjunto) {
      this.confirmarEliminacionAbierta = true;
      this.documentoActivo = adjunto;
    },
    async eliminarAdjunto() {
      try {
        this.eliminandoDocumento = true;
        const adjuntoId = this.documentoActivo.id;
        const { status } =
          await this.services.DocumentosAniosFiscalServices.eliminarDocumento(
            adjuntoId
          );
        if ([200, 201].includes(status)) {
          this.pushAppMessage({
            type: "success",
            message: "Documento eliminado correctamente.",
          });
          this.clearForm();
          this.cargarDocumentosAnioFiscalPac();
          this.$emit("on-action");
        }
      } catch (error) {
      } finally {
        this.eliminandoDocumento = false;
        this.confirmarEliminacionAbierta = false;
      }
    },
    manejarPaginacionRegistros(paginacion) {
      const { pagina, cantidad_por_pagina } = paginacion;
      this.paginaActual = pagina;
      this.registrosPorPagina = cantidad_por_pagina;
      this.cargarDocumentosAnioFiscalPac();
    },
    async visualizarAdjunto(adjunto) {
      this.modalVisualizacionAdjuntoAbierta = true;
      const idAdjunto = adjunto.id;
      toggleLoadable(this.visualizacionAdjunto);
      const { data } =
        await this.services.DocumentosAniosFiscalServices.visualizarAdjunto(
          idAdjunto
        );
      
      setLoadableResponse(this.visualizacionAdjunto, data, { isFile: true });
      this.previsualizacionAbierta = true;
    },
    manejarVisibilidadModalVisualizacionAdjunto(visible) {
      this.modalVisualizacionAdjuntoAbierta = visible;
    },
    // Cargar documentos
    async cargarDocumentosAnioFiscalPac() {
      const filtros = {
        pagination: true,
        per_page: this.registrosPorPagina,
        page: this.paginaActual,
      };

      togglePageable(this.documentos);
      const { data, headers } =
        await this.services.DocumentosAniosFiscalServices.cargarDocumentos(
          filtros
        );
      setPageableResponse(this.documentos, data, headers);
    },
    editarDocumento(item) {
      this.form.id_anio_fiscal = item.id_anio;
      this.form.numero_proceso = item.numero_proceso;
      this.form.fecha_aprobacion = item.fecha_aprobacion;
      this.idDocumentoAnioFiscal = item.id;
      this.siendoEditado = true;
    },
    async actualizarDocumentoPAC() {
      const fromData = convertToFormData(this.form);

      this.actualizarDocumento = true;
      await this.services.DocumentosAniosFiscalServices.actualizarDocumentoPAC(
        this.idDocumentoAnioFiscal,
        fromData
      ).finally(() => (this.actualizarDocumento = false));
      this.cargarDocumentosAnioFiscalPac();
      this.confirmarActualizarAbierta = false;
      this.siendoEditado = false;
      this.clearForm();
    },
    cancelarActualizar() {
      this.clearForm();
      this.siendoEditado = false;
    },
  },
  watch: {
    isOpen(value) {
      if (isNil(value)) return;

      this.internalOpen = value;
    },
    internalOpen(value) {
      this.$emit("on-visibility-change", value);
    },
  },
  created() {
    this.cargarDocumentosAnioFiscalPac();
  },
};
</script>
